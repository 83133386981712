import React, { useState } from 'react'

import { useLocation } from '@reach/router'

import Link from 'src/components/link/index'
import Dialog from './dialog'

import styles from './index.module.scss'

const Footer = () => {
	const { pathname } = useLocation()

	const [open, setOpen] = useState(false)
	const [address, setAddress] = useState(``)

	const openDialog = (address) => {
		setOpen(true)
		setAddress(address)
	}

	return (
		<footer className={styles.footer}>
			<div className={styles.wrapper}>
				<div className={styles.logo}>
					<Link to={pathname === '/' ? '/#' : '/'}>WE ARE THE FIT</Link>
				</div>

				<ul className={styles.sns}>
					<li>
						<Link to="https://www.facebook.com/wearethefit/">facebook</Link>
					</li>
					<li>
						<Link to="https://twitter.com/WEARETHEFIT24">
							<svg
								width="30"
								height="30"
								viewBox="0 0 30 30"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle cx="15" cy="15" r="15" fill="white" />
								<path
									d="M16.2021 14.1505L20.9045 8.68433H19.7902L15.7071 13.4305L12.4459 8.68433H8.68457L13.6161 15.8614L8.68457 21.5935H9.79895L14.1108 16.5814L17.5548 21.5935H21.3161L16.2018 14.1505H16.2021ZM14.6758 15.9246L14.1761 15.2099L10.2005 9.52321H11.9121L15.1205 14.1126L15.6201 14.8273L19.7907 20.7927H18.0791L14.6758 15.9249V15.9246Z"
									fill="black"
								/>
							</svg>
						</Link>
					</li>
					<li>
						<Link to="https://www.instagram.com/wearethefit_24/">instagram</Link>
					</li>
				</ul>

				<div className={styles.message}>
					We are not just a gym. We want to be a place to create a purpose for your life to move your body.
				</div>

				<div className={styles.gyms}>
					<dl>
						<dt>
							<Link to="/gyms/toritsudai/">
								WE ARE THE FIT <br />
								TORITSUDAI
							</Link>
						</dt>
						<dd>
							<div className={styles.address}>
								〒152-0031 <br />
								東京都目黒区中根1丁目3-11
							</div>
							<div className={styles.tel}>TEL：03-5726-8065</div>
							<button onClick={() => openDialog(`info@thefit.jp`)}>お問い合わせはこちら</button>
						</dd>
					</dl>
					<dl>
						<dt>
							<Link to="/gyms/ekoda/">
								WE ARE THE FIT <br />
								EKODA
							</Link>
						</dt>
						<dd>
							<div className={styles.address}>
								〒176-0005 <br />
								東京都練馬区旭丘1-71-6 プロパティ江古田3F
							</div>
							<div className={styles.tel}>TEL：03-5906-5777</div>
							<button onClick={() => openDialog(`ekoda@thefit.jp`)}>お問い合わせはこちら</button>
						</dd>
					</dl>
				</div>

				<ul className={styles.nav1}>
					<li>
						<Link to={pathname === '/' ? '/#news' : '/news/'}>NEWS</Link>
					</li>
					<li>
						<Link to="/#about">ABOUT</Link>
					</li>
					<li>
						<Link to="/#gyms">GYMS</Link>
					</li>
					<li>
						<Link to="/#price">PRICE</Link>
					</li>
					<li>
						<Link to="/faq/">FAQ</Link>
					</li>
					<li>
						<Link to="/beginners/">FOR BEGINNER</Link>
					</li>
					<li>
						<Link to="/iatf/">I AM THE FIT</Link>
					</li>
					<li>
						<Link to="https://body-s.jp/ekoda/">Body Styling</Link>
					</li>
				</ul>

				<ul className={styles.nav2}>
					<li>
						<Link to="/company/">会社概要</Link>
					</li>
					<li>
						<Link to="/agreement/">会員規則</Link>
					</li>
					<li>
						<Link to="/privacy/">プライバシーポリシー</Link>
					</li>
					<li>
						<Link to="/law/">特定商取引法に基づく表記</Link>
					</li>
				</ul>

				<div className={styles.copyright}>
					<span>© 2020 WE ARE THE FIT. All Rights Reserved</span>
				</div>
			</div>

			<Dialog isOpen={open} close={() => setOpen(false)} address={address} />
		</footer>
	)
}

export default Footer
